import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-4" }
const _hoisted_3 = { class: "col-xxl-4" }
const _hoisted_4 = { class: "col-xxl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MixedWidget2 = _resolveComponent("MixedWidget2")!
  const _component_ListWidget5 = _resolveComponent("ListWidget5")!
  const _component_ListWidget3 = _resolveComponent("ListWidget3")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MixedWidget2, {
        "widget-classes": "card-xl-stretch mb-xl-8",
        "widget-color": "danger",
        "chart-height": "200",
        "stroke-color": "#cb1e46"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ListWidget5, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-10" })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ListWidget3, { "widget-classes": "card-xxl-stretch mb-xl-3" })
    ])
  ]))
}